.iconButton {
  background: transparent;
  border: 0;
  font-family: var(--text-family);
  font-weight: var(--text-label-weight);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.iconWithLabel {
  padding: var(--mod-1-multiplier) var(--mod-2-multiplier)
    var(--mod-1-multiplier) var(--mod-1-multiplier);
}

.iconWithoutLabel {
  padding: var(--mod-1-multiplier);
}

.iconButton:after {
  background: var(--colors-tint-purple-light);
  border-radius: 50%;
  position: absolute;
  content: '';
  transition: var(--defaults-animation-time);
}

.iconButtonAutofill {
  width: 100%;
  height: 100%;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}

.iconButton:hover .iconButtonIcon {
  animation: bounce var(--defaults-animation-time);
}

.iconButton:focus {
  box-shadow: var(--defaults-focus-outline);
}

/******************************* 
      SMALL ICON BUTTON
*******************************/
.iconButtonSmall {
  font-size: var(--text-label-small-size);
  border-radius: var(--defaults-borderRadius-small);
}

.iconButtonSmall .iconButtonIcon {
  width: var(--mod-2-point-5-multiplier);
  height: var(--mod-2-point-5-multiplier);
}

.iconButtonSmall .iconButtonIconWithName {
  margin-right: var(--mod-1-multiplier);
}

.iconButtonSmall .iconButtonIconWithoutName {
  margin-right: 0;
}

.iconButtonSmall:after {
  width: var(--mod-3-multiplier);
  height: var(--mod-3-multiplier);
  top: var(--mod-0-point-75-multiplier);
  left: var(--mod-0-point-75-multiplier);
}

.iconButtonSmall:hover:after {
  transform: scale(12);
}

/******************************* 
      MEDIUM ICON BUTTON
*******************************/

.iconButtonMedium {
  font-size: var(--text-label-medium-size);
  border-radius: var(--defaults-borderRadius-medium);
}

.iconButtonMedium .iconButtonIcon {
  width: var(--mod-3-point-5-multiplier);
  height: var(--mod-3-point-5-multiplier);
  margin-right: var(--mod-1-point-5-multiplier);
  padding: var(--mod-0-point-75-multiplier);
}

.iconButtonMedium:after {
  width: var(--mod-3-point-5-multiplier);
  height: var(--mod-3-point-5-multiplier);
  top: calc(50% - var(--mod-1-point-5-multiplier));
  left: var(--mod-1-point-25-multiplier);
}

.iconButtonMedium:hover:after {
  transform: scale(20);
}

/******************************* 
      TYPES (colors)
*******************************/
.iconButtonBrand {
  color: var(--brand-default);
}
