.citationsSearchHeader {
  display: flex;
  align-items: center;
}

.citationsSearchTitle {
  color: var(--colors-white);
}

.citationsSearchOr {
  color: var(--colors-white);
  margin: 0;
  padding: 0 var(--mod-2-multiplier);
}
.uploadContainer {
  display: flex;
  align-items: center;
}
@media (--screen-mobile-max) {
  .uploadContainer {
    display: none;
  }
}
