.searchLoading {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  padding: 50px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.searchLoadingText {
  margin-top: 30px;
}
