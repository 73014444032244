.viewCitationsOuterContent {
  position: sticky;
  top: 0;
  left: 0;
  height: calc(100vh - var(--defaults-header-size) - var(--mod-10-multiplier));
  margin-left: -20px;
  padding-left: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.viewCitationsContent {
  display: flex;
  flex-direction: column;
  background: var(--background-default);
  border-radius: var(--defaults-borderRadius-medium);
  padding: var(--mod-2-multiplier);
  min-height: 100%;
}

.viewCitationsHeaderContent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: var(--mod-0-point-5-multiplier);
  padding-bottom: var(--mod-1-point-5-multiplier);
}

.viewCitationsHeaderExportWrapper {
  position: relative;
}

.alertMessage {
  font-size: 1.2em;
  z-index: 5;
  top: 50%;
  left: 50%;
  position: absolute;
  opacity: 0.9;
  background-color: var(--color-teal-medium);
  color: white;
  padding: 20px;
  border-radius: var(--defaults-borderRadius-small);
  animation: fadeOut ease 1s;
  animation-delay: var(--alert-fade-delay);
  transform: translate(-50%, -50%);
  display: inline-block;
}

.alertMessage p {
  margin-left: 6px;
  margin-bottom: 2px;
  display: inline-block;
}

.undoButtonContainer {
  bottom: 15px;
  right: 15px;
  position: absolute;
  padding: 18px;
  display: flex;
  gap: 18px;
  border-radius: 6px;
  color: rgb(241, 131, 131);
  background-color: rgb(250, 226, 225);
  height: 50px;
  width: 250px;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  z-index: 101;
}

.undoButton {
  border-radius: 6px;
  background-color: rgb(231, 87, 87);
  border: none;
  color: white;
  padding: 4px;
  margin: 0;
  width: 30%;
  line-height: 25px;
  cursor: pointer;
}

.undoButton:hover {
  background-color: rgb(208, 68, 68);
}

@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media (--screen-mobile-max) {
  .viewCitationsOuterContent {
    height: auto;
    padding-bottom: 130px;
  }
  .viewCitationsHeaderContent {
    padding-top: var(--mod-1-point-5-multiplier);
    padding-bottom: 0;
    padding-left: var(--mod-1-point-5-multiplier);
  }
  .exportButton {
    display: none;
  }
  .viewCitationsContent {
    border-radius: 0;
    padding: var(--mod-1-point-5-multiplier);
  }
}
