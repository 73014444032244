.container {
  display: flex;
  flex-direction: column;
}

.citationTypePickerList {
  padding: var(--mod-1-multiplier) var(--mod-2-multiplier) var(--mod-2-multiplier) var(--mod-2-multiplier);
  width: 100%;
}

.citationTypePickerListContent {
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
}

.citationTypePickerListShowMore {
  max-width: 160px;
  margin: 0 auto;
  padding-bottom: 4%;
}
@media (--screen-mobile-max) {
  .citationTypePickerList {
    padding: 0;
  }
  .citationTypePickerListContent {
    justify-content: left;
    margin: 0;
  }
  .citationTypePickerListShowMore {
    margin: 0;
  }
}
