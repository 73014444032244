.outerWrapper {
  margin: 8px 0 0 0;
  transition: margin-bottom 1000ms ease-in-out;
}
.empty {
  margin-bottom: 0;
  margin-top: 0;
}
.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
