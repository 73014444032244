.hero {
  margin-left: 24px;
  margin-top: 20px;
  margin-right: 24px;
  background: rgba(140, 78, 241, 0.1);
  border-radius: 16px;
  padding: 24px 18px;
}
@media (--screen-mobile-max) {
  .hero {
    display: none;
  }
}
