.citationTypePickerSearch {
  width: 100%;
  border-radius: var(--defaults-borderRadius-small);
  height: var(--mod-6-multiplier);
  font-size: var(--text-paragraph-medium-size);
  margin: var(--mod-2-multiplier) 0;
  padding: 0 var(--mod-1-multiplier);
}

@media (--screen-mobile-max) {
  .citationTypePickerSearch {
    width: 100%;
    padding-left: 0;
    height: var(--mod-7-multiplier);
    margin: 0;
  }
}
