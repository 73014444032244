.exportCitationsWrapper {
  z-index: 3;
  background-color: var(--background-strong);
  border-radius: var(--defaults-borderRadius-small);
  box-shadow: var(--defaults-shadow-large);
  right: 0;
  top: 60px;
  position: absolute;
  max-width: 536px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.exportCitationsTop {
  align-items: center;
  flex-grow: 1;
}
.exportCitationsRadioButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
}
.exportCitationsRadioButtons {
  display: inline-flex;
  align-items: center;
  padding: 0px;
  font-size: 13px;
}

.copyForRadio {
  margin: 0 5px 0 15px;
  text-align: center;
}

.copyLabel {
  font-weight: bold;
}
.exportCitationsDivider {
  width: 496px;
  border: 1px solid;
  border-color: var(--colors-grey-light);
}
.exportCitationsBottom {
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: center;
}
.exportCitationsLeft {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  gap: 17px;
  flex: 1;
}
.exportCitationsRight {
  display: flex;
  flex-direction: column;
  gap: 17px;
  flex: 1;
}
.exportCitationsButtonTop {
  width: 496px;
  height: 48px;
  margin-bottom: 20px;
}
.exportCitationsButtonBottom {
  width: 243px;
  height: 48px;
}
.buttonLabel {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
