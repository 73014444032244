.overallWrapper {
  display: flex;
  align-items: center;
}
.row {
  margin: 2px 0;
}
.favicon {
  max-width: 100%;
  max-height: 100%;
  display: inline;
}
.faviconContainer {
  height: 50px;
  margin-right: 0;
  float: left;
}
.details {
  display: flex;
  flex-direction: row;
  align-items: center;
}
