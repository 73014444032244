.searchError {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
  line-height: 20px;
  text-align: center;
}

.errorMsgContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.errorMsgText {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
}

.pdfDetails,
.chromeAppTip,
.validUrlSample {
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.6;
  font-size: 0.8em;
  width: 80%;
}

.validUrlSample {
  margin-top: -15px;
}

.invalidUrlContainer {
  text-align: center;
  font-size: 0.75em;
  white-space: pre-wrap;
}

.invalidUrlContainer > strong {
  display: inline-block;
  padding-right: 4px;
}

.invalidUrlText {
  background-color: var(--colors-lavender-lighter);
}

.fillManuallyContainer {
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: -15px;
}

@media screen and (max-width: 1024px) {
  .searchError {
    flex-direction: column;
  }
}
