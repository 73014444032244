.container {
  position: relative;
  margin: 10px 0;
}
.iconContainer {
  position: absolute;
  left: var(--mod-2-multiplier);
  top: calc(50% - var(--mod-1-multiplier));
}

.input {
  padding: 0 var(--mod-2-multiplier) 0 var(--mod-6-multiplier);
  background: var(--background-strong);
  border-radius: var(--defaults-borderRadius-small);
  border: 2px solid var(--colors-grey-light);
  height: var(--mod-7-multiplier);
  font-size: var(--text-paragraph-medium-size);
  width: 100%;
}
.input:hover {
  border-color: var(--colors-purple-light);
}
.input:focus {
  border-color: var(--colors-purple-medium);
  outline: none !important;
}
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
