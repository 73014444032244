.warning {
  display: flexbox;
  flex-direction: row;
  color: var(--colors-yellow-medium);
  font-size: 0.7em;
  margin-top: 3px;
}
.warningExtraMessage {
  margin-left: 6px;
}
.suggestionFixButton {
  border: 0;
  margin-top: 3px;
  text-align: center;
  font-size: 0.8em;
  justify-content: center;
  border-top: 2px solid var(--colors-yellow-light);
  border-bottom: 2px solid var(var(--colors-yellow-light));
  border-radius: var(--defaults-borderRadius-veryTiny);
  background-color: var(--dynamic-bg-color);
}
.suggestionFixButton:hover,
.suggestionFixButton:focus {
  background-color: var(--dynamic-bg-color-hover);
  cursor: pointer;
}
