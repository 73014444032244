.inputsContainer {
  display: flex;
}
.inputContainer {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.inputContainer:last-child {
  margin-right: 0;
}
