.citationInputName {
  width: 100%;
}
.citationInputContainer {
  width: 100%;
  display: flex;
}
.citationNameTypeContainer {
  display: flex;
  margin-top: 20px;
  margin-left: var(--mod-0-point-5-multiplier);
  height: 41px;
  align-items: center;
}
.inputContainer {
  margin: 0 10px;
}
.toggleLink {
  cursor: pointer;
  background: transparent;
  padding: 0;
  align-self: center;
  font-size: 12px;
  border-radius: var(--defaults-borderRadius-tiny);
  border: 1px solid var(--colors-grey-dark);
  display: inline-block;
  margin: var(--mod-0-point-5-multiplier) var(--mod-0-point-5-multiplier)
    var(--mod-1-point-5-multiplier);
}
.toggleLinkItem {
  display: inline-block;
  color: var(--colors-grey-dark);
  padding: var(--mod-1-multiplier);
}
.toggleLinkItem:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.toggleLinkItem:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.toggleLinkItemActive {
  background: var(--colors-grey-dark);
  color: var(--colors-grey-lightest);
}
@media (--screen-large-max) {
  .citationInputContainer {
    flex-direction: column;
  }
  .citationNameTypeContainer {
    margin-left: 4px;
    margin-top: 10px;
    height: auto;
    margin-bottom: 20px;
  }
  .inputContainer {
    margin-top: -5px;
    margin-left: 4px;
  }
}
