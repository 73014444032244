.trashIcon {
  margin: 0;
  background-color: transparent;
  padding: 0;
  color: var(--colors-red-medium);
  cursor: pointer;
  border: 0;
  display: inline-flex;
  opacity: 0%;
  transition: opacity 150ms ease-in-out, background-color 250ms ease-in-out;
}
