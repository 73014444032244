.citationStylePicker {
  position: absolute;
  background: var(--colors-white);
  z-index: 2;
  top: var(--mod-10-multiplier);
  left: var(--mod-2-multiplier);
  width: calc(100% - var(--mod-4-multiplier));
  height: calc(100% - var(--mod-12-multiplier));
  box-shadow: var(--defaults-shadow-medium);
  border-radius: var(--defaults-borderRadius-small);
}
.citationStylePickerInner {
  position: relative;
  height: 100%;
  padding: var(--mod-2-multiplier);
  display: flex;
  flex-direction: column;
}
.citationStylePickerHeader {
  position: absolute;
  top: var(--mod-2-multiplier);
  width: calc(100% - var(--mod-4-multiplier));
  display: flex;
  flex-direction: column;
}
.citationStylePickerCloseButton {
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  cursor: pointer;
  top: calc(-1 * var(--mod-1-multiplier));
  right: calc(-1 * var(--mod-1-multiplier));
}
.citationStyleList {
  margin-top: 90px;
  word-break: break-word;
  overflow-y: auto;
}
.citationStyleListLoading {
  padding: var(--mod-2-multiplier);
  font-style: italic;
  text-align: center;
}
.citationStyleListItem {
  display: flex;
  background: transparent;
  border: 0;
  width: 100%;
  cursor: pointer;
  min-height: var(--mod-6-multiplier);
  border-radius: var(--defaults-borderRadius-small);
  padding: var(--mod-0-point-5-multiplier) var(--mod-2-multiplier);
  align-items: center;
  justify-content: space-between;
}
.citationStyleListItemCurrent {
  background-color: var(--colors-tint-purple-light);
}
.status {
  flex: none;
  margin-left: 5px;
}
.citationStyleListItem:hover,
.citationStyleListItem:focus {
  background-color: var(--colors-grey-lightest);
}
@media (--screen-mobile-max) {
  .citationStylePicker {
    position: fixed;
    top: var(--mod-1-multiplier);
    height: calc(100% - var(--mod-2-multiplier));
  }
  .citationStyleListItem {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}
