.inputContainer {
  width: 100%;
  margin-right: 10px;
}

.todayContainer {
  margin-top: 26px;
}
@media (--screen-large-max) {
  .todayContainer {
    display: none;
  }
}
