.openInformationModal {
  margin-left: 5px;
  cursor: pointer;
}
.modalHeaderCloseButtonWrapper {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
@media (--screen-mobile-max) {
  .modalContainer button {
    display: none;
  }
}
