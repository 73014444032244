.citationInputForm {
  position: relative;
  border-radius: var(--defaults-borderRadius-medium);
  height: calc(100vh - var(--defaults-header-size) - var(--mod-10-multiplier));
  background: var(--colors-white);
  border: 2px solid var(--colors-white);
  box-shadow: var(--defaults-shadow-medium);
}

.citationInputFormSelectContainer {
  display: flex;
  align-items: center;
}
.citationInputFormButtonContainer {
  display: flex;
}
.citationInputFormHeaderContainer {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--colors-grey-light);
}
.citationInputFormHeader {
  padding: var(--mod-3-multiplier);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.labelWithModal {
  display: flex;
}

.citationInputFormContent {
  padding: var(--mod-2-multiplier) var(--mod-3-multiplier);
  height: calc(100% - 89px);
  overflow: auto;
  margin-top: 89px;
}
.halfWidth {
  display: inline-block;
  width: calc(50% - 5px);
}
.twoThirdsWidth {
  display: inline-block;
  width: calc(67% - 5px);
}
.thirdWidth {
  display: inline-block;
  width: calc(33% - 5px);
}
.left {
  margin-right: 10px;
}
@media screen and (max-width: 800px) {
  .citationInputFormButtonContainer {
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
  }
  .citationInputFormContent {
    margin-top: 145px;
    height: calc(100% - 145px);
  }
}
@media (--screen-mobile-max) {
  .citationInputForm {
    box-shadow: 0;
    height: auto;
  }
  .deleteContainer {
    display: none;
  }
  .citationInputFormButtonContainer {
    height: auto;
  }
  .citationInputFormContent {
    margin-top: 72px;
    height: calc(100% - 72px);
    padding: var(--mod-2-multiplier);
  }
  .citationInputFormHeader {
    padding: var(--mod-2-multiplier);
  }
  @media screen and (min-height: 600px) {
    .citationInputFormHeaderContainer {
      width: 100%;
      position: sticky;
      border-bottom: 1px solid var(--colors-grey-light);
      top: 0;
      background-color: var(--colors-white);
    }
    .citationInputFormContent {
      margin-top: 0;
      height: 100%;
      padding: var(--mod-2-multiplier);
    }
  }
  .twoThirdsWidth {
    width: 100%;
  }
  .thirdWidth {
    width: 100%;
  }
}
