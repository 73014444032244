.linkToWebSearch {
  color: var(--colors-purple-dark);
  cursor: pointer;
  font-weight: 500;
}
.linkToWebSearch:hover {
  color: var(--colors-purple-light);
}

.searchResult {
  padding: var(--mod-2-multiplier);
  cursor: pointer;
  border-bottom: 1px solid var(--colors-grey-light);
  word-break: break-word;
}
.searchResult.highlighted {
  background: var(--colors-grey-lightest);
}
.searchResult:hover {
  background: var(--colors-grey-lightest);
}
.showMoreButtonContainer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}
.searchInfo {
  padding: var(--mod-2-multiplier);
  text-align: center;
  font-style: italic;
}
