.addOutestContainer {
  background-color: var(--colors-white);
  margin: 52px 0 var(--mod-1-multiplier) var(--mod-1-multiplier);
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.addOuterMiddleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.noCitations {
  padding-top: 15vh;
}
.withCitations {
  padding-top: 20vh;
}

.addCitationText {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.addOuterContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.showMoreDesktop {
  margin-top: var(--mod-3-multiplier);
}
.showMoreMobile {
  display: none;
}
@media (--screen-mobile-max) {
  .addOuterMiddleContainer {
    padding-top: 0;
    align-items: flex-start;
  }
  .addOutestContainer {
    flex-basis: auto; /* this is required for safari <= 14.0.2 because flex-basis:0 breaks the mobile layout */
    margin-top: var(--mod-1-point-5-multiplier);
  }
  .addCitationText {
    display: none;
  }
  .showMoreDesktop {
    display: none;
  }
  .showMoreMobile {
    display: block;
    margin-top: var(--mod-1-multiplier);
  }
}
@media screen and (max-width: 350px) {
  .addOuterContainer {
    width: 100%;
  }
}
