.citationInputSearch {
  position: relative;
}
.citationsSearch {
  background: var(--brand-default);
  border-radius: var(--defaults-borderRadius-medium);
  box-shadow: var(--defaults-shadow-medium);
  padding: var(--mod-2-multiplier) var(--mod-3-multiplier);
  position: sticky;
  top: 0;
  left: 0;
}

.citationsSearchClose {
  position: absolute;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  top: var(--mod-1-multiplier);
}

.horizontalPositioningWord {
  left: var(--mod-1-multiplier);
  right: initial;
}

.horizontalPositioningNonWord {
  left: initial;
  right: var(--mod-1-multiplier);
}
.citationsSearchInput {
  margin-top: var(--mod-2-multiplier);
  flex-grow: 1;
}
@media (--screen-mobile-max) {
  .citationInputSearchBack {
    display: none;
  }
  .citationsSearch {
    border-radius: 0;
    padding: var(--mod-2-multiplier);
  }
}
