.citationCopyPopup {
  width: 100%;
  position: relative;
}
.citationHelper {
  display: none;
  align-self: center;
  position: absolute;
  top: calc(-1 * var(--mod-3-multiplier));
  padding: 6px 10px;
  border-radius: 8px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.recentlyCopied {
  background-color: var(--color-teal-medium);
  width: 96px;
}

.notRecentlyCopied {
  background-color: var(--colors-grey-darkest);
  width: 116px;
}
.helperText {
  font-size: 14px;
}
.citationTextOuter {
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: var(--mod-0-point-5-multiplier);
  border-radius: 5px;
}
.citationTextOuter:hover {
  background: var(--dynamic-bg-color);
}
.citationTextOuter:hover .citationHelper {
  display: inline;
}
.citationText {
  display: block;
}
.withHangingIndent {
  text-indent: -2rem;
  margin-left: 2rem;
  padding-right: 2rem;
}
.withoutHangingIndent {
  text-indent: 0;
  margin-left: 0;
  padding-right: 0;
}
@media (hover: none) {
  .citationHelper {
    display: inline;
  }
}
