.invisible {
  visibility: hidden;
}
.copyIcon {
  cursor: pointer;
  border: 0;
  margin-left: 0px;
  opacity: 0%;
  background-color: transparent;
  border-radius: var(--defaults-borderRadius-veryTiny);
  padding: 1px 4px;
  vertical-align: text-bottom;
  display: inline-flex;
  transition: opacity 200ms ease-in-out, background-color 300ms ease-in-out;
}

.citationButton {
  display: inline-flex;
  align-self: flex-end;
  justify-content: center;
  flex-direction: row;
}
.helperText {
  margin-left: 3px;
  font-size: 10px;
  color: black;
  align-self: top;
  transition: opacity 300ms ease-in-out;
}
.copyIcon:not(.recentlyCopied) {
  color: black;
  background-color: var(--background-hover-color);
}
.copyIcon:hover:not(.recentlyCopied) {
  color: black;
  background-color: var(--colors-lavender-light);
}
