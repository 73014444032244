.addContainer {
  display: inline-flex;
  background: transparent;
  border-radius: 16px;
  border: 0;
  color: white;
  flex-direction: row;
  align-items: center;
  text-align: left;
  background-color: var(--dynamic-bg-color);
  cursor: pointer;
  margin: 5px;
}

.addContainerDefault {
  padding: 15px 25px;
  min-height: 56px;
  width: auto;
}
.addContainerSmall {
  width: 180px;
  padding: 5px 15px;
  min-height: 45px;
}
.addContainer:hover {
  background-color: var(--dynamic-bg-color-darkened);
}
.citeTextLarge {
  font-size: 17px;
  display: block;
}
.citeTextSmall {
  font-size: 14px;
  display: block;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.circleDesktop {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}
.circleMobile {
  width: 24px;
  height: 24px;
  display: none;
}

@media (--screen-mobile-max) {
  .addContainer {
    margin: var(--mod-0-point-5-multiplier) 0;
    display: flex;
    min-height: auto;
    border-radius: 5px;
    padding: var(--mod-0-point-5-multiplier) var(--mod-1-multiplier);
    padding-right: var(--mod-2-multiplier);
    width: auto;
  }
  .addContainer:hover {
    border-radius: 5px;
  }
  .circleDesktop {
    display: none;
  }
  .citeTextLarge, .citeTextSmall {
    font-size: 14px;
  }
  .circleMobile {
    display: flex;
  }
  .citeText:first-child {
    display: inline-flex;
  }
  .citeText:last-child {
    margin-left: 3.5px;
    display: inline-flex;
  }
  .citeText {
    margin-left: var(--mod-1-multiplier);
  }
}
@media screen and (max-width: 350px) {
  .addContainer {
    width: calc(100% - var(--mod-1-multiplier));
  }
  .citeTextSmall {
    font-size: 14px;
  }
}
