.citation {
  position: relative;
  margin-bottom: var(--mod-1-multiplier);
}

.citationMain {
  user-select: text;
  padding: var(--mod-1-multiplier);
  border-radius: var(--defaults-borderRadius-small);
  background: var(--background-strong);
  display: flex;
  flex-direction: column;
  border: 2px solid var(--background-strong);
  width: 100%;
  text-align: left;
}
.citationMain:focus {
  outline: 2px solid var(--background-medium);
}
.citationMain:hover {
  border-bottom-right-radius: 0;

  box-shadow: 0px 1px 4px rgba(66, 0, 174, 0.08),
    0px 2px 8px rgba(66, 0, 174, 0.08), 0px 4px 16px rgba(66, 0, 174, 0.08);
}
.citationMainEmpty:hover {
  border-bottom-right-radius: var(--defaults-borderRadius-small);
  border-bottom-left-radius: var(--defaults-borderRadius-small);
}
.citationMain.selected {
  background: var(--background-medium);
  border-color: var(--background-medium);
  color: var(--content-strongest);
}

.citationFooter {
  color: #616161;
  font-size: 90%;
  margin-top: var(--mod-0-point-5-multiplier);
  top: calc(100% - 8px);
  right: 0;
  display: none;
  position: absolute;
  background: white;
  padding: 6px 5px;
  z-index: 100;
  width: 50%;
  border-bottom-right-radius: var(--defaults-borderRadius-small);
  border-bottom-left-radius: var(--defaults-borderRadius-small);
}

.citationMain.selected .citationFooter {
  background: var(--background-medium);
  display: flex;
}
.citationMain:hover .citationFooter {
  display: flex;

  box-shadow: 0px 1px 4px rgba(66, 0, 174, 0.08),
    0px 2px 8px rgba(66, 0, 174, 0.08), 0px 4px 16px rgba(66, 0, 174, 0.08);
}
.citationIntextAndFootnote {
  flex-grow: 1;
}

.glowing {
  animation: 4.5s glow-load 0s forwards linear;
}

.locationInfo {
  font-weight: bold;
}
.footnote {
  display: inline;
}

.citationTextContainer {
  display: flex;
  vertical-align: center;
}
.dragIcon {
  cursor: move;
  padding-top: 3px;
  margin-right: 15px;
  fill: var(--colors-purple-medium);
}

@keyframes glow-load {
  from {
    background-color: rgb(250, 245, 229);
  }
  to {
    background-color: var(--background-strong);
  }
}
.citationMain.selected .citationFooter {
  position: static;
  display: flex;
  align-self: flex-end;
  box-shadow: none !important;
  margin-right: -10px;
  margin-bottom: -10px;
}
@media (hover: none) {
  .citationFooter {
    width: 50%;
    position: relative;
    display: flex;
    box-shadow: none !important;
  }
}
@media (--screen-mobile-min) {
  .citationFooter {
    width: 75%;
  }
}
@media (--screen-mobile-max) {
  .citationMain:hover {
    border-bottom-left-radius: 0;
  }
  .citationFooter {
    width: 100%;
  }
}

.citation:hover :global(.trash-icon),
.citationMain:focus :global(.trash-icon) {
  opacity: 100%;
}
