.inputsContainer {
  border-left: 2px solid var(--colors-grey-light);
  padding-left: var(--mod-1-multiplier);
  margin-top: var(--mod-1-multiplier);
  margin-bottom: var(--mod-2-multiplier);
  display: flex;
  width: 100%;
  align-content: stretch;
}
@media screen and (max-width: 800px) {
  .inputsContainer {
    flex-direction: column;
  }
}
