.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footerLink {
  font-weight: 500;
  color: var(--colors-grey-medium);
  margin-right: var(--mod-1-multiplier);
  font-size: var(--text-paragraph-small-size);
}
@media (--screen-mobile-max) {
  .container {
    display: none;
  }
}
