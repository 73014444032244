.skFlow {
  width: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skFlowDot {
  width: 10px;
  height: 10px;
  background-color: var(--colors-purple-light);
  border-radius: 50%;
  animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite
    both;
}

.skFlowDot:nth-child(1) {
  animation-delay: -0.3s;
}
.skFlowDot:nth-child(2) {
  animation-delay: -0.15s;
}

@keyframes sk-flow {
  0%,
  80%,
  100% {
    transform: scale(0.3);
  }
  40% {
    transform: scale(1);
  }
}
