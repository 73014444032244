.field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  margin-bottom: 4px;
  font-size: 0.9em;
  border-radius: var(--defaults-borderRadius-veryTiny);
  background-color: var(--colors-yellow-light);
  align-items: baseline;
  padding: 4px 8px;
}

.field:last-child {
  margin-bottom: 0;
}

.field .fieldOptions {
  display: flex;
  justify-content: center;
  align-content: center;
}
.fieldOptionsButton {
  flex: 1;
  display: flex;
  border: 0;
  justify-content: center;
  border-top: 2px solid var(--colors-yellow-light);
  border-bottom: 2px solid var(--colors-yellow-light);
  border-radius: var(--defaults-borderRadius-veryTiny);
}
.fieldOptionsButton:first-child {
  border-left: 2px solid var(--colors-yellow-light);
  border-right: 1px solid var(--colors-yellow-light);
}
.fieldOptionsButton:last-child {
  border-right: 1px solid var(--colors-yellow-light);
  border-right: 2px solid var(--colors-yellow-light);
}
.fieldOptionsButton {
  cursor: pointer;
  padding: 2px 4px;
  margin-left: 8px;
  background-color: #eedca5;
}
.fieldOptionsButton:hover,
.fieldOptionsButton:focus {
  background-color: #e5cc7a;
}

.fieldTextIcon {
  font-weight: 600;
  background: var(--colors-yellow-medium);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
  margin-right: 4px;
}
.fieldOptionsSpacer {
  padding: 0 2px;
}
@media screen and (max-width: 320px) {
  .field {
    width: 100%;
    justify-content: space-between;
  }
  .fieldOptionsButton {
    font-size: 1em;
  }
  .fieldTextIcon {
    height: 13px;
    width: 13px;
  }
  .fieldTextMissing {
    display: none;
  }
}
