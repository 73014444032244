.citationInputSearchSingle {
  position: relative;
}
.citationsSearch {
  background: var(--brand-default);
  border-radius: var(--defaults-borderRadius-medium);
  box-shadow: var(--defaults-shadow-medium);
  padding: var(--mod-2-multiplier) var(--mod-3-multiplier);
  position: sticky;
  top: 0;
  left: 0;
}
.citationsSearchClose {
  position: absolute;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  top: var(--mod-1-multiplier);
}

.word {
  left: var(--mod-1-multiplier);
  right: initial;
}

.default {
  left: initial;
  right: var(--mod-1-multiplier);
}

.citationsSearchInput {
  margin-top: var(--mod-2-multiplier);
  flex-grow: 1;
}
.showMoreButtonContainer {
  text-align: center;
  margin-top: 20px;
}
@media (--screen-mobile-max) {
  .citationInputSearchBack {
    display: none;
  }
  .citationsSearch {
    border-radius: 0;
    padding: var(--mod-2-multiplier);
  }
}

.searchResult {
  margin-top: 10px;
  border-radius: 8px;
  padding: var(--mod-2-multiplier);
  background: var(--colors-grey-lightest);
  word-break: break-word;
}
.buttonContainer {
  margin-top: 13px;
  margin-bottom: 6px;
}
.noSearchResults {
  padding: var(--mod-2-multiplier);
  font-style: italic;
  text-align: center;
}
