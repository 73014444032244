.wrapper {
  min-height: 40px;
  cursor: pointer;
  border-radius: var(--defaults-borderRadius-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  width: 0px;
}
.dropdownListContainer {
  display: none;
  background-color: var(--background-strong);
  border-radius: var(--defaults-borderRadius-small);
  box-shadow: var(--defaults-shadow-large);
  overflow: hidden;
}
.dropdownListContainerActive {
  display: block;
  position: absolute;
  top: -80px;
  right: 0;
  z-index: 1;
}

.dropdownList {
  margin: 0;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 150px;
}
.dropdownListItem {
  border-radius: 3px;
}
.dropdownListItem button {
  border: 0;
  width: 100%;
  color: black;
  white-space: nowrap;
  appearance: none;
  background: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
}
.dropdownListItem button:hover {
  background: var(--colors-purple-lighter);
  color: var(--colors-purple-medium);
}
.dropdownListItemLine {
  margin: 0.5px 0;
  width: 100%;
  margin: 0 auto;
  border: 0;
  border-top: 1px solid var(--colors-grey-light);
}
