.citationTypePickerDropdown {
  background-color: var(--colors-white);
  padding-bottom: 2%;
  min-width: 100%;
}
.citationTypeList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.citationTypeListItem {
  display: flex;
  background: transparent;
  border: 0;
  outline: 0;
  width: 85%;
  cursor: pointer;
  min-height: var(--mod-4-multiplier);
  border-radius: var(--defaults-borderRadius-small);
  padding: var(--mod-0-point-5-multiplier) var(--mod-2-multiplier);
  align-items: center;
  justify-content: space-between;
}
.citationTypeListItem:hover {
  background: var(--colors-grey-lightest);
}
.citationTypePickerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.citationTypePickerHeader {
  min-width: 180px;
}
.citationTypeUl {
  text-align: center;
}
.contactUsCta {
  padding-top: 10px;
  font-weight: var(--text-label-weight);
  text-align: center;
}
.hideCta {
  display: none;
}
@media (--screen-mobile-max) {
  .contactUsCta {
    font-size: var(--text-label-small-size);
    min-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .citationTypePickerInner {
    align-items: flex-start;
  }
  .citationTypeList,
  .citationTypeUl,
  .citationTypePickerInner {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .citationTypePickerHeader {
    width: 100%;
  }
}
