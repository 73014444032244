.citationInputForm {
  position: relative;
  border-radius: var(--defaults-borderRadius-medium);
  height: calc(100vh - var(--defaults-header-size) - var(--mod-10-multiplier));
  background: var(--colors-white);
  border: 2px solid var(--colors-white);
  box-shadow: var(--defaults-shadow-medium);
}

.primaryContainer {
  display: flex;
  align-items: flex-start;
  background: var(--colors-grey-lighter);
  border-radius: var(--defaults-borderRadius-small);
  padding: 16px 16px 11.2px 16px;
}

.pageNumberContainer,
.inTextContainer {
  display: flex;
  flex-direction: column;
  margin-right: 1.25em;
}

.inTextContent {
  display: inline-block;
  margin: 0.5em 0.5em 1em 0;
}

.examplesContainer {
  padding: var(--mod-1-point-5-multiplier);
  font-size: 13px;
  line-height: 2;
}

.examplesHeader {
  display: inline-block;
  margin: 0.5em 0;
}
.examplesContainerLine {
  opacity: 0.4;
  margin: 1.5em 0;
}

.footnoteContainer {
  margin-top: 30px;
  text-align: center;
}

.inTextCitation {
  background-color: var(--colors-lavender-lighter);
}

.exampleInTextCitation {
  margin-right: 0.75em;
}
.citationInputFormButtonContainer {
  display: flex;
}
.citationInputFormHeaderContainer {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--colors-grey-light);
}
.citationInputFormHeader {
  padding: var(--mod-3-multiplier);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.citationInputFormContent {
  padding: var(--mod-2-multiplier) var(--mod-3-multiplier);
  height: calc(100% - 100px);
  overflow: auto;
  margin-top: 100px;
}
.parenthesesExampleContainer {
  margin-bottom: 1.5em;
}

.inTextCitationForm :global(.trash-icon),
.inTextCitationForm :global(.copy-icon),
.inTextCitationForm :global(.copy-icon .helper-text) {
  opacity: 100% !important;
}

@media screen and (max-width: 800px) {
  .citationInputFormButtonContainer {
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
  }
  .citationInputFormContent {
    margin-top: 145px;
    height: calc(100% - 145px);
  }
}
@media (--screen-mobile-max) {
  .citationInputForm {
    box-shadow: 0;
    height: auto;
  }
  .citationInputFormButtonContainer {
    height: auto;
  }
  .citationInputFormContent {
    margin-top: 72px;
    height: calc(100% - 72px);
    padding: var(--mod-2-multiplier);
  }
  .citationInputFormHeader {
    padding: var(--mod-2-multiplier);
  }
}
