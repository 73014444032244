.citationStyle {
  background: white;
  display: flex;
  position: relative;
  min-width: var(--mod-18-multiplier);
  font-size: 16px;
  width: auto;
  cursor: pointer;
  border: 2px solid var(--colors-grey-light);
  padding: var(--mod-1-point-5-multiplier) var(--mod-2-multiplier);
  padding-right: var(--mod-5-multiplier);
  margin-right: var(--mod-2-multiplier);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: var(--defaults-borderRadius-small);
  transition: var(--defaults-animation-time);
}

.citationStyle:hover,
.citationStyle:focus {
  border-color: var(--colors-purple-light);
}

.downArrowContainer {
  position: absolute;
  right: 16px;
  top: 10px;
}

@media (--screen-mobile-max) {
  .citationStyle {
    color: var(--content-strong);
    font-size: 14px;
    padding: var(--mod-1-multiplier) var(--mod-2-multiplier);
    margin-right: var(--mod-1-multiplier);
  }
  .downArrowContainer {
    top: 8px;
  }
}
