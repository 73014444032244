.addCitations {
  margin: var(--mod-9-multiplier) var(--mod-1-multiplier)
    var(--mod-1-multiplier) 0;
  flex-basis: 0;
  flex-grow: 1;
  border-radius: 16px;
}

.glow {
  animation-name: glow;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* we have two diff outputs here so animation will fire on every re-render triggered by glow action */
.animationDurationOne {
  animation-duration: 0.8s;
}
.animationDurationTwo {
  animation-duration: 0.81s;
}

@keyframes glow {
  0% {
  }
  50% {
    border-color: var(--colors-purple-medium);
    box-shadow: 0 0 20px var(--colors-purple-medium);
  }
  100% {
  }
}

@media (--screen-mobile-max) {
  .addCitations {
    margin: 0;
  }
}
